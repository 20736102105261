@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600|Paytone+One');
$maintextcolor : #f5ec16; //#f5f2b8;

#client-images-box {
    //background: rgba(0, 0, 0, 0.5) !important;
    //border: 5px solid rgba(0, 0, 0, 0.6) !important;
}

.d-none{
    display: none !important;

}
#pdf-container{

width: 95vw;
    height: 95vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 0 !important;
    position: fixed;
    top: 2.5vh;
    left: 2.5vw;
    background: rgba(0, 0, 0, .4);
    .close{
        position: absolute;
        top:20px;
        right:20px;
        color:#f5ec16;
        fill: #f5ec16;
        font-size: 50px;
    }
}

#client-images {
    display: flex;
    flex-wrap: wrap;
    gap: 4vw 20px;
    justify-content: space-between;
    align-items: center;
}

#client-images>div {
    flex: 0 0 calc(15% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:768px) and (orientation: portrait) {
    #client-images>div {
        flex: 0 0 calc(33% - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media screen and (min-width:1600px) {
    #client-images>div {
        flex: 0 0 calc(11% - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#client-images img {

    max-width: 100%;
    height: auto;
}
.coming-soon-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-position: center center;
    background-size: cover;
    height: 470px;
}
.coming-soon-image-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img{
        max-width: 100%;
        max-height: 470px;
    }
}


.coming-soon-image h2 {
    text-align: center;
}




  #recent,
  #helter,
  #dagenham,
  #eightyfive,
  #woodland {
    h3 {
      font-size: 50px;
      text-align: center;

      @media (max-width: 991px) {
        font-size: 45px;
      }

      @media (max-width: 991px) and (orientation: landscape) {
        font-size: 40px;
        margin-bottom: 20px;
      }

      @media (max-width: 767px) {
        font-size: 40px;
        margin-bottom: 20px;
      }

      @media (max-width: 575px) {
        font-size: 35px;
      }
    }

    .show {
      -webkit-transition: opacity 1s;
      /* Safari */
      transition: opacity 1s;
      padding: 15px;

      img {
        max-width: 100%;

        cursor: pointer;
      }

      .name {
        color: $maintextcolor;
        font-size: 25px
      }

      .title {
        font-size: 22px;
        color: white;

        @media (max-width: 575px) {
          font-size: 16px;
        }
      }

      .desc {
        font-size: 22px;
      }
    }


  }


@media only screen and (max-width: 991px) and (orientation:landscape) {
    #turn {

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #e4e4e4;
        background-image: url('/images/lock.jpg');
        background-size: cover;
        background-position: 50% 50%;

        .box{
            background: rgba(0, 0, 0, 0.5);
            padding: 20px;
            border: 2px solid rgba(0, 0, 0, 0.5);
        
        }
        h4 {
            font-family: 'Paytone One';
            font-size: 25px;
            max-width: 70%;
            margin: 0 auto;
            color: #fff;
        }

        .image-rotate {
            border: 10px solid #fff;
            border-radius: 50%;
            width: 130px;
            height: 130px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f5ec16;
            margin: 0 auto 20px;

            img {
                width: 100px;
                height: 100px;
                flex-basis: 100px;
                ;
            }
        }


    }

    #fullpage {
        display: none;
    }
}

@media only screen and (orientation:portrait) {
    #turn {
        display: none;
    }

    #fullpage {
        display: block;
    }
}


@media only screen and (min-width: 992px){

#turn {
    display: none;
}
}


#fullpage {

    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    &.loaded {
        opacity: 1;
        visibility: visible;

    }

    .logo {
        position: fixed;
        top: 20px;
        left: 20px;
        width: 10%;
        min-width: 100px;
    }

    .font-yellow {
        color: $maintextcolor !important;
    }


    @media (min-width: 576px) {
        .container {
            max-width: 90%;
        }
    }





    .section {
        .fp-bg {
            background-size: cover;
            background-position: center center;
        }



        .inner {
            width: 100%;
            height: 100%;
            display: -webkit-box;
            -webkit-box-pack: center;
            /* justify-content */
            -webkit-box-align: center;
            /* align-items */

            .box {
                background: rgba(0, 0, 0, 0.7);
                padding: 40px;
                border: 5px solid rgba(0, 0, 0, 0.8);
                font-family: 'Source Sans Pro';
                font-size: 25px;
                color: #fff;
                font-weight: 300;
                margin: 20px 0;

                @media (max-width: 991px) {
                    font-size: 23px;
                }

                @media (max-width: 991px) and (orientation: landscape) {
                    font-size: 18px;
                    padding: 30px;
                }

                @media (max-width: 767px) {
                    font-size: 18px;
                    padding: 20px;
                    border: 3px solid rgba(0, 0, 0, 0.9);
                }

                @media (max-width: 575px) {
                    font-size: 18px;
                }

                .fa {
                    color: $maintextcolor;
                }

                .yellow {
                    color: $maintextcolor;
                    font-family: 'Paytone One';
                    text-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, .4);
                    -webkit-text-fill-color: $maintextcolor;
                    /* Will override color (regardless of order) */
                    -webkit-text-stroke-width: 3px;

                    -webkit-text-stroke-color: white;
                    letter-spacing: 2px;
                }

                h2 {
                    font-family: 'Paytone One';
                    color: #fff;
                    font-size: 58px;
                    margin-bottom: 30px;

                    @media (max-width: 991px) {
                        font-size: 50px;
                    }

                    @media (max-width: 991px) and (orientation: landscape) {
                        font-size: 30px;
                    }

                    @media (max-width: 767px) {
                        font-size: 35px;
                        margin-bottom: 20px;
                    }

                    @media (max-width: 575px) {
                        font-size: 29px;
                    }

                    @media (max-width: 400px) {
                        font-size: 25px;
                        margin-bottom: 15px;
                    }
                }

                h3 {
                    font-family: 'Paytone One';
                    color: #fff;
                    font-size: 35px;

                    @media (max-width: 991px) {
                        font-size: 30px;
                    }

                    @media (max-width: 991px) and (orientation: landscape) {
                        font-size: 25px;
                    }

                    @media (max-width: 575px) {
                        font-size: 25px;
                    }

                    @media (max-width: 400px) {
                        font-size: 20px;
                    }
                }

                h4 {
                    font-size: 1.75rem;
                }


                &.white {
                    background: rgba(255, 255, 255, 0.7);
                    color: #000;

                }


            }


        }
    }



    #page-1-fe,#page-1,#section1-bw {

        .inner {
            h1 {
                text-align: center;
                margin: 0;

                span {
                    display: block;
                }

                .top {
                    font-size: 160px;

                    color: $maintextcolor;
                    font-family: 'Paytone One';
                    font-weight: 900;
                    text-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, .4);
                    -webkit-text-fill-color: $maintextcolor;
                    /* Will override color (regardless of order) */
                    -webkit-text-stroke-width: 3px;
                                        @media screen and (max-width:500px) {
                                                -webkit-text-stroke-width: 1px;
                                            }
                    -webkit-text-stroke-color: white;

                    letter-spacing: 2px;

                    @media (max-width: 991px) {
                        font-size: 120px;

                    }

                    @media (max-width: 767px) {
                        font-size: 100px;
                    }

                    @media (max-width: 575px) {
                        font-size: 60px;
                    }
                }

                .bottom {
                    font-size: 65px;
                    font-family: 'Paytone One';
                    font-style: italic;
                    color: #ffffff;
                    font-weight: 300;
                    text-shadow: 2px 2px rgba(0, 0, 0, 0.4);

                    display: block;

                    width: 100%;
                    line-height: 1;

                    @media (max-width: 991px) {
                        font-size: 50px;

                    }

                    @media (max-width: 767px) {
                        font-size: 40px;
                    }

                    @media (max-width: 575px) {
                        font-size: 30px;
                    }


                }

            }
        }

    }

    #page-9 {

        #contact-info {
            img {
                flex: 0 0 200px;
                max-width: 200px;
                padding-right: 15px;

                @media (max-width: 991px) {
                    flex: 0 0 150px;
                    max-width: 150px;
                }

                @media (max-width: 767px) {
                    flex: 0 0 120px;
                    max-width: 120px;

                }

                @media (max-width: 400px) {
                    flex: 0 0 100px;
                    max-width: 100px;

                }
            }

            .address {

                padding-bottom: 0px;

                @media (max-width: 575px) {
                    font-size: 14px;
                }

                @media (max-width: 400px) {
                    font-size: 12px;
                }

                a {
                    color: #fff;

                    @media (max-width: 991px) {
                        font-size: 25px;
                    }

                    @media (max-width: 991px) and (orientation: landscape) {
                        font-size: 20px;
                    }

                    @media (max-width: 767px) {
                        font-size: 20px;
                        margin-bottom: 20px;
                    }

                    @media (max-width: 575px) {
                        font-size: 14px;
                    }

                    @media (max-width: 400px) {
                        font-size: 12px;
                    }

                    &:hover,
                    &:focus {
                        color: $maintextcolor;
                        text-decoration: none;
                    }
                }
            }
        }



    }

    #page-10 {

        .inner {
            h1 {
                text-align: center;
                margin: 0;

                span {
                    display: block;
                }

                .top {
                    font-size: 160px;

                    color: $maintextcolor;
                    font-family: 'Paytone One';
                    font-weight: 900;
                    text-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, .4);
                    -webkit-text-fill-color: $maintextcolor;
                    /* Will override color (regardless of order) */
                    -webkit-text-stroke-width: 3px;
                    -webkit-text-stroke-color: white;

                    letter-spacing: 2px;

                    @media (max-width: 991px) {
                        font-size: 120px;

                    }

                    @media (max-width: 767px) {
                        font-size: 100px;
                        //   -webkit-text-stroke-width: 2px;
                    }

                    @media (max-width: 575px) {
                        font-size: 60px;
                        //-webkit-text-stroke-width: 2px;
                    }
                }

                .bottom {
                    font-size: 65px;
                    font-family: 'Paytone One';
                    font-style: italic;
                    color: #ffffff;
                    font-weight: 300;
                    text-shadow: 2px 2px rgba(0, 0, 0, 0.4);
                    /* background: rgba(255, 255, 0, 0.5); */
                    display: block;
                    /* padding: 8px; */
                    width: 100%;
                    line-height: 1;

                    /* letter-spacing: 13px; */
                    @media (max-width: 991px) {
                        font-size: 50px;

                    }

                    @media (max-width: 767px) {
                        font-size: 40px;
                    }

                    @media (max-width: 575px) {
                        font-size: 30px;
                    }

                }

            }

            .year {
                font-size: 40px;
                text-shadow: 2px 2px rgba(0, 0, 0, 0.4);
                color: #fff;

                @media (max-width: 991px) {
                    font-size: 30px;

                }

                @media (max-width: 767px) {
                    font-size: 25px;
                }

                @media (max-width: 575px) {
                    font-size: 20px;
                }
            }
        }



    }


}